<template>
  <section>
    <dash-header/>
    <dash-content/>
  </section>
</template>

<script>
import {
  BCard,
  BCardText,
  BRow,
  BCol,
  BDropdown,
  BDropdownItem,
  BDropdownDivider,
  BBreadcrumb,
  BButton,
  BButtonGroup,
  BFormInput,
  BInputGroup,
  BInputGroupPrepend,
  BInputGroupAppend,
} from 'bootstrap-vue'
import flatPickr from 'vue-flatpickr-component'
import Ripple from 'vue-ripple-directive'
import DashHeader from './dashComp/dashHeader.vue'
import DashContent from './dashComp/dashContent.vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  components: {
    BCard,
    BCardText,
    BRow,
    BCol,
    BDropdown,
    BDropdownItem,
    BDropdownDivider,
    BButtonGroup,
    BFormInput,
    BInputGroup,
    BInputGroupPrepend,
    BInputGroupAppend,
    flatPickr,
    BBreadcrumb,
    BButton,
    DashContent,
    DashHeader,
  },
  data() {
    return {}
  },
  directives: {
    Ripple,
  },
  beforeDestroy() {
    this.$store.commit('kurumDetayAction', 'remove')
  },
  beforeCreate() {
    if (
        Object.entries(this.$store.getters.getKurumDetay.roomList).length === 0
    ) {
      this.$store
          .dispatch('kurumDetay', localStorage.getItem('raporKurumKodu'))
          .then((res, position = 'bottom-right') => {
            const mesaj = this.$store.getters.notificationSettings(res)
            if (mesaj) {
              this.$toast(
                  {
                    component: ToastificationContent,
                    props: {
                      title: 'Başarılı',
                      text: mesaj,
                      icon: 'ThumbsUpIcon',
                      variant: 'success',
                    },
                  },
                  { position },
              )
            }
          })
          .catch(err => {
            const mesaj = this.$store.getters.notificationSettings(err)
            if (mesaj) {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: 'Hata!',
                  text: mesaj,
                  icon: 'ThumbsDownIcon',
                  variant: 'danger',
                },
              })
            }
          })
    }
  },
}
</script>

<style scoped></style>
