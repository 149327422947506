<template>
  <div>
    <b-row class="match-height mt-50 justify-content-between">
      <!--      :style="{marginTop : width < 876 ? '150px':  width < 924  ? '100px': width < 1225 ? '50px!important':''}"-->
      <b-breadcrumb :items="breadcrumb"/>
      <b-link v-b-tooltip.hover.right="'Mevcut Güne Dön'" @click="kurumPost"
      >
        <h3>{{ this.$store.getters.getWorkDay }}</h3>
      </b-link>
      <div>
        <b-button-group class="mr-1">
          <b-button
              v-ripple.400="'rgba(113, 102, 240, 0.15)'"
              variant="outline-primary"
              @click="$store.dispatch('dateBack',  { root: true })"
          >
            <feather-icon icon="SkipBackIcon"/>
          </b-button>
          <b-button
              v-ripple.400="'rgba(113, 102, 240, 0.15)'"
              variant="outline-primary"
              @click="$store.dispatch('dateNext',  { root: true })"
          >
            <feather-icon icon="SkipForwardIcon"/>
          </b-button>
        </b-button-group>
        <b-input-group class="float-right flat-date">
          <flat-pickr v-model="dateDefault" class="form-control"/>
        </b-input-group>
      </div>
    </b-row>
  </div>
</template>

<script>
import {
  BLink,
  VBTooltip,
  BRow,
  BCol,
  BDropdown,
  BDropdownItem,
  BDropdownDivider,
  BBreadcrumb,
  BButton,
  BButtonGroup,
  BFormInput,
  BInputGroup,
  BInputGroupPrepend,
  BInputGroupAppend,
} from 'bootstrap-vue'
import flatPickr from 'vue-flatpickr-component'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

const Turkish = require('flatpickr/dist/l10n/tr.js').default.tr
flatpickr.localize(Turkish)
flatpickr(flatPickr)
import Ripple from 'vue-ripple-directive'
import { useWindowSize } from '@vueuse/core'

export default {
  setup() {
    const {
      width,
    } = useWindowSize()
    return {
      width,
    }
  },
  components: {
    BLink,
    BRow,
    BCol,
    BDropdown,
    BDropdownItem,
    BDropdownDivider,
    BButtonGroup,
    BFormInput,
    BInputGroup,
    BInputGroupPrepend,
    BInputGroupAppend,
    flatPickr,
    BBreadcrumb,
    BButton,
  },
  data() {
    return {
      dateDefault: null,
      breadcrumb: [
        {
          text: 'Dashboard',
        },
        {
          text: 'Konaklamalar',
          active: true,
        },
      ],
    }
  },
  watch: {
    dateDefault(newVal) {
      this.$store.dispatch('selectDate', { dateDefault: newVal })
    },
  },
  methods: {
    kurumPost() {
      this.$store.dispatch('kurumDetay', localStorage.getItem('raporKurumKodu'))
    },
    takvimUpdate() {
      this.$store
          .dispatch('takvimGuncellestir')
          .then((res, position = 'bottom-right') => {
            const mesaj = this.$store.getters.notificationSettings(res)
            this.$toast(
                {
                  component: ToastificationContent,
                  props: {
                    title: 'Başarılı',
                    text: mesaj,
                    icon: 'ThumbsUpIcon',
                    variant: 'success',
                  },
                },
                { position }
            )
          })
          .catch(e => {
            const mesaj = this.$store.getters.notificationSettings(e)
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Hata!',
                text: mesaj,
                icon: 'ThumbsUpIcon',
                variant: 'danger',
              },
            })
          })
    },
  },
  directives: {
    'b-tooltip': VBTooltip,
    Ripple,
  },
}
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-flatpicker.scss";
</style>
<style>
html .content.app-content {
  padding: calc(1rem + 4.45rem + 1.3rem) 2rem 0 !important;
}

col {
  display: none !important;
}
</style>
<style scoped>
.match-height {
  margin-top: -30px;
}

.flat-date {
  width: 50%;
}

.date-side-dropdown {
  margin-left: 80px;
}

.breadcrumb {
  padding: 0.3rem 0rem !important;
}
</style>
